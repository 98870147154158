import React from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import styles from "./OrderForm.module.scss";

const OrderForm = ({show, onHide, handleClose, keyboard, backdrop, handleFormSubmit}) => {

        const { register, handleSubmit, formState: { errors } } = useForm();
        const onSubmit = data => handleFormSubmit(data);

    return (
        <div>
            <Modal
        show={show}
        onHide={onHide}
        backdrop={backdrop}
        keyboard={keyboard}
        centered
        className={styles.orderFormBox}
      >
          <form  onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header  closeButton>
          <Modal.Title>Fill Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form.Group>
          <Form.Label>Full Name*</Form.Label>
          <Form.Control type="text"
           {...register("fullname", { required: true })}
           />
            {errors.fullname && <p className={styles.errMsg}>Full Name is required</p>}
        </Form.Group>

        <Form.Group>
          <Form.Label>Phone No.*</Form.Label>
          <Form.Control type="text"
           {...register("phone", { required: true })}
           />
           {errors.phone && <p className={styles.errMsg}>Phone No. is required</p>}
        </Form.Group>

        <Form.Group>
          <Form.Label>Address*</Form.Label>
          <Form.Control type="text"
           {...register("address", { required: true })}
           />
           {errors.address && <p className={styles.errMsg}>Address is required</p>}
        </Form.Group>
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
              Submit
            </Button>
        </Modal.Footer>
        </form>
      </Modal>
        </div>
    )
}

export default OrderForm;

import React, {useState} from "react";
import ProductCard from "../../components/organisms/productcard/ProductCard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./Products.module.scss";
import { useFirestoreConnect } from "react-redux-firebase";
import { DeviceUUID } from "device-uuid";


const Products = () => {
  const { id } = useParams();
  var uuid = new DeviceUUID().get();
  const [limits, setLimits] = useState(8);

  useFirestoreConnect([
    {
      collection: "products",
      where: [["categoryId", "==", id]],
      limit: limits,
    },
  ]);

  useFirestoreConnect([
    {
      collection: "cart",
      where: [["deviceId", "==", uuid]],
    },
  ]);

  const products = useSelector((state) => state.firestore.ordered.products);

  return (
    <div className="container">
      <div className="row">
        {products &&
          products.map((product) => (
            <div
              className={`col-md-3 col-sm-6 col-6 ${styles.productsBox}`}
              key={product.id}
            >
              <ProductCard
                id={product.id}
                name={product.productName}
                description={product.productDescription}
                image={product.productImg}
                alt={product.productName}
                price={product.price}
                stockStatus={product.stockStatus}
              />
            </div>
          ))}
      </div>


      {products &&(
        <div className="row">
      <div className="offset-md-5 col-md-2 col-12 d-grid">
            <button className={`btn btn-warning ${styles.loadMoreBtn} noOutline`} onClick={()=>setLimits(limits +12)}>Load More</button>
            </div>
      </div>
      )}
      
    </div>
  );
};
export default Products;

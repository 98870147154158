import React, {useState, useEffect, useRef} from 'react';
import { useFirestore } from "react-redux-firebase";
import style from './CartItems.module.scss';

const CartItems = (props) => {

    const firestore = useFirestore();
    var [product, setProduct] = useState({});

    var itemQty = useRef();

    const getProductByCart = async () => {
        try {
          const docRef = firestore.collection("products").doc(props.id);
          const result = await docRef.get();
          if (result.exists) {
            setProduct(result.data());
          } else {
            alert("somthing went wrong");
          }
        } catch (e) {
          console.log("error", e.message);
        }
      };

      useEffect(() => {
        getProductByCart();
      }, [props.id]);

      const cartQtyUpdate=(cartDoc)=>{
        firestore.collection('cart').doc(cartDoc).update({
          qty : itemQty.current.value
        })
        // console.log(itemQty.current.value)
        // console.log(cartDoc)
      }

      const cartRemove=(cartDoc)=>{
        const docRef = firestore.collection('cart').doc(cartDoc)
        docRef.delete();
        // console.log(cartDoc)
      }

    return (
            <div className={`row ${style.cartItemBox}`}>
            <div className="offset-md-3 col-md-3 col-6">
                <img src={product.productImg} alt={product.productName}/>
            </div>
            <div className="col-md-3 col-6">
            <div onClick={()=>cartRemove(props.cartDoc)} className={style.deleteBox}>Delete</div>
                <p>{product.productName}</p>
                <span>Qty: </span> 
                <input 
                 type="number"
                 className={style.inputQty}
                 ref={itemQty}
                 min={1}
                 required
                 defaultValue={props.qty}
                 onBlurCapture={() =>cartQtyUpdate(props.cartDoc)}
                 />
                <p>Price: {product.price}</p>
                
            </div>
        </div>
    )
}

export default CartItems;

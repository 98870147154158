import React, {useRef, useState, useEffect } from "react";
import { useFirestore, useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import styles from './AddProduct.module.scss';
import {Form, Button, Col, Row } from 'react-bootstrap';
import uploadImg from '../../assets/images/uploadimage.png'

const AddProduct = () => {

  let history = useHistory()
  const firestore = useFirestore();
  const firebase = useFirebase();
  const { id } = useParams();
  const uuid = uuidv4();

  var productName = useRef(null);
  var productCategory = useRef(null);
  var productDescription = useRef(null);
  var price = useRef(null);
  var moq = useRef(null);
  var stockStatus = useRef(null);

  const [image, setImage] = useState('');
  const [imagePath, setImagePath] = useState('');


  const category = useSelector((state) => state.firestore.ordered.category)
    useFirestoreConnect([
        {
            collection:'category',
        }
    ])

  const getProductById = async () =>{
    if(id){
    try {
        const docRef = firestore.collection("products").doc(id);
        const result = await docRef.get();
        if(result.exists){
          const formData =result.data()
          if(id){
            productName.current.value = formData.productName;
            productDescription.current.value = formData.productDescription;
            productCategory.current.value = formData.categoryId;
            price.current.value = formData.price;
            moq.current.value = formData.moq;
            stockStatus.current.value = formData.stockStatus;
            setImagePath(formData.productImg)
          }
        }else{
            alert('somthing went wrong');
        }
    } catch (e) {
        console.log('error', e.message);
    }
  }
} 

  useEffect(() =>{
    getProductById();
  },[]);

  const onSubmitHandle= (e) =>{
    e.preventDefault();
    if(id){
        updateData()
    }else{
      const formData ={
        productName: productName.current.value.toLowerCase(),
        categoryId: productCategory.current.value,
        productDescription: productDescription.current.value,
        price: price.current.value,
        moq: moq.current.value,
        stockStatus: stockStatus.current.value,
        productImg: '',
      }
    const storageRef = firebase.storage().ref()
    const imageRef = storageRef.child('products/'+uuid+1);
    const uploadTask = imageRef.put(image)
    uploadTask.on("state_changed", console.log, console.error, () => {
    imageRef.getDownloadURL()
    .then((url) => {
      formData.productImg = url;
      firestore.collection("products")
      .add({
          ...formData,
          createdAt: firestore.FieldValue.serverTimestamp()
        })
    });
    
    })
    history.push('/');
    }
  }


  const updateData = async ()=>{
    var formData ={
      productName: productName.current.value.toLowerCase(),
      categoryId: productCategory.current.value,
      productCategory: productCategory.current.value,
      productDescription: productDescription.current.value,
      price: price.current.value,
      moq: moq.current.value,
      stockStatus: stockStatus.current.value,
      createdAt: firestore.FieldValue.serverTimestamp()
    }
    if(image){
      const storageRef = firebase.storage().ref()
    const imageRef = storageRef.child('products/'+uuid+1);
    const uploadTask = imageRef.put(image)
    uploadTask.on("state_changed", console.log, console.error, () => {
      imageRef.getDownloadURL()
      .then((url) => {
        const updateRef = firestore.collection("products").doc(id);
        updateRef.update({
          ...formData,
          productImg: url,
          createdAt: firestore.FieldValue.serverTimestamp()
        })
          history.push('/');
      });
    })
    }else{
      const updateRef = firestore.collection("products").doc(id);
      updateRef.update({
        ...formData
      })
      history.push('/');
    }
      
  }


  const onImageChange = (e) => {
    const reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(file);
          setImage(file);
          setImagePath(reader.result)
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setImage(null);
    }
  };

  return (
    <div className="container">
      <form onSubmit={onSubmitHandle}>
        <div className={`offset-md-3 col-md-6 ${styles.addProductPage}`}>
          <div className={styles.imgUploadBox}>
            <input
            type="file"
            accept="image/x-png,image/jpeg"
            onChange={(e) => {onImageChange(e); }}
          />
          <img className={styles.imgUpload} src={uploadImg} alt="img" />
         </div>
         {imagePath &&(
          <img className={styles.preViewImg} src={imagePath} alt="img"/>
         )}

        <Form.Group>
          <Form.Label>Product Name *</Form.Label>
          <Form.Control type="text"
           placeholder="type your product name"
           ref={productName}
           required={true}
           />
        </Form.Group>

        <Form.Group>
          <Form.Label>Product Description</Form.Label>
          <Form.Control 
           as="textarea"
           rows={3} 
           placeholder="type your product description"
           ref={productDescription}
           />
        </Form.Group>
        
        <Row>
        <Form.Group as={Col}>
          <Form.Label>Price *</Form.Label>
          <Form.Control type="number"
           placeholder="product price"
           ref={price}
          required={true}
           />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Moq</Form.Label>
          <Form.Control type="number"
           placeholder="minimum order quantity"
           ref={moq}
           />
        </Form.Group>
        </Row>

        <Row>
        <Form.Group as={Col}>
            <Form.Label>Category *</Form.Label>
            <Form.Control ref={productCategory} as="select">
            {category && category.map(cat =>(
                <option key={cat.id} value={cat.id} defaultValue={cat.id}>{cat.categoryName}</option>
              ))}
            </Form.Control>
        </Form.Group>

        <Form.Group as={Col}>
            <Form.Label>Stock Status *</Form.Label>
            <Form.Control ref={stockStatus} as="select">
                <option value="In Stock">In Stock</option>
                <option value="Out of Stock">Out of Stock</option>
            </Form.Control>
        </Form.Group>

        </Row>

        <div className={`d-grid ${styles.submitBtn}`}>
        <Button type="submit" variant="primary">
        {id ? "update product" : "Add Product"}
        </Button>
        </div>

        </div>
      </form>
    </div>
  );
};

export default AddProduct;
import React, {Fragment, useState, useRef} from 'react'
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from 'react-redux'
import {DeviceUUID} from 'device-uuid';
import CartItems from '../../components/organisms/cart-items/CartItems';
import style from './Cart.module.scss';
import {useHistory, Link } from 'react-router-dom';
import OrderForm from '../../components/organisms/OrderForm/OrderForm';


const Cart =()=> {

    const firestore = useFirestore();
    var devId = new DeviceUUID().get();
    const history = useHistory();
    
    useFirestoreConnect([
        {
            collection:'cart',
            where: [['deviceId', '==', devId]],
        }
    ])

    const cartItem = useSelector((state) => state.firestore.ordered.cart)

    const [isShow, setisShow] = useState(false)
    const openCheckOut=()=>{
        setisShow(true)
    }


    const checkOut =(cartItem, data)=>{
        // console.log(data)
        // console.log(cartItem)
        const cartData = cartItem.map(item =>(
            {
                deviceId :item.deviceId,
                productId : item.productId,
                qty: item.qty,
            }
        )) 
        firestore.collection("orders")
      .add({
          name: data.fullname,
          phone: data.phone,
          address: data.address,
          items: cartData,
          createdAt: firestore.FieldValue.serverTimestamp()
        }).then(()=>{
            deleteCartItems()
            setisShow(false)
            history.push('/')
        })
        
    }

    const deleteCartItems =()=>{
        try {
            let cartRef = firestore.collection("cart").where('deviceId', "==", devId)
        cartRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc) {
                doc.ref.delete();
              });
        })
        } catch (e) {
            console.log(e.message)
        }
    }
    
    const handleClose = () => setisShow(false);
    

    return (
        <div className={`container ${style.cartContainer}`}>
            {cartItem && cartItem.map((cart, index) =>(
                <Fragment key={index}>
                    <CartItems
                    cartDoc={cart.id}
                    id={cart.productId}
                    qty={cart.qty}
                    />
                </Fragment>
            ))}
            
            {cartItem && cartItem.length > 0 && (
                <div className="d-grid gap-2 col-md-4 col-12 mx-auto">
                <button onClick={openCheckOut} className="btn btn-primary">Checkout</button>
                </div>
            )}

            <OrderForm
            show={isShow}
            backdrop={'static'}
            onHide={handleClose}
            handleClose={handleClose}
            handleFormSubmit={(data) =>checkOut(cartItem, data)}
            />
            
            {cartItem && cartItem.length == 0 &&(
                <div className={style.emptyBox}>
                <h2>Your cart is empty!</h2>
                <Link to="/">
                <button className="btn btn-primary">Shop Now</button>
                </Link>
                </div>
                )}
        </div>
    )
}
export default Cart;

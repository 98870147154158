import React from 'react';
import s from './CategoryCard.module.scss';


const CategoryCard = ({src, alt, heading}) => {
    return (
        <div className={s.cetegoryBox}>
            <img src={src} alt={alt}/>
            <h2>{heading}</h2>
        </div>
    )
}

export default CategoryCard;

import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from './components/organisms/header/Header';
import Footer from './components/organisms/footer/footer';
import Home from './containers/home/Home';
import Products from './containers/products/Products';
import Product from './containers/product/Product';
import AddProduct from './containers/add-product/AddProduct';
import PrivateRoutes from './PrivateRoutes';
import Login from './containers/login/Login';
import Cart from './containers/Cart/Cart';
import Orders from './containers/orders/Orders';
import AddCategory from './containers/AddCategory/AddCategory';
import Categories from './containers/Categories/Categories';
import SearchResult from './containers/SearchResult/SearchResult';


function App() {
  var screenSize = window.screen.width
  return (
     <Router>
       <div className="stickyHeader">
     <Header/>
     </div>
       <Switch>
         <Route exact path="/" component={Home} />
         <Route exact path="/login" component={Login} />
         <Route exact path="/cart" component={Cart} />
         <PrivateRoutes exact path="/categories" component={Categories} />
         <PrivateRoutes exact path="/add-category/:id?" component={AddCategory} />
         <Route exact path="/products/:id" component={Products} />
         <Route exact path="/product/:id" component={Product} />
         <Route exact path="/search/:searchText?" component={SearchResult} />
         <PrivateRoutes exact path="/orders" component={Orders} />
         <PrivateRoutes exact path="/add-product/:id?" component={AddProduct} />
       </Switch>
       {/* {screenSize >= 768 &&(
        <Footer/>
       )} */}
     </Router>
     
  );
}

export default App;

import React from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import styles from './Categories.module.scss';

const Categories = () => {

      const categories = useSelector((state) => state.firestore.ordered.category);
    return (
        <div className={`container ${styles.catContainer}`}>
            <div className="row">            
            {categories && categories.map(cat =>(
                <div key={cat.id} className="col-md-3">
                    <div className={styles.catBox}>
                <Link to={`add-category/${cat.id}`}>
                <img className={styles.catImg} src={cat.categoryImg}/>
                <div className={styles.headText}>{cat.categoryName}</div>
                </Link>
                </div>
                </div>
            ))}
            </div>
            
        </div>
    )
}

export default Categories;

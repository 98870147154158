import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { createStore, combineReducers } from 'redux';
import {firebaseReducer} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';
import {composeWithDevTools} from 'redux-devtools-extension'

const fbConfig = {
  apiKey: "AIzaSyDsJJ6lTQFjQxwhi4SovbkehqQ_KylcemQ",
  authDomain: "hcmseller-6bdac.firebaseapp.com",
  projectId: "hcmseller-6bdac",
  storageBucket: "hcmseller-6bdac.appspot.com",
  messagingSenderId: "27175653552",
  appId: "1:27175653552:web:b0599fe25943c0e53e6b75"
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)
firebase.firestore()

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState, composeWithDevTools())

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

export default store;
import React, { Fragment, useRef, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  InputGroup,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import mobileMenuImg from "../../../assets/images/bars.png";
import cartImg from "../../../assets/images/cart.svg";
import crossIcon from "../../../assets/images/cross.svg";
import brandLogo from "../../../assets/images/hcmlogo.png";
import searchIcon from "../../../assets/images/search.svg";
import MenuDrawar from "../MenuDrawar/MenuDrawar";
import styles from "./Header.module.scss";

const Header = () => {
  const firebase = useFirebase();
  const router = useHistory();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const searchTerm = useRef();
  var screenSize = window.screen.width;
  const winLocation = window.location.origin;
  const [searchText, setSearchText] = useState();
  const [isSearch, setIsSearch] = useState(false);

  useFirestoreConnect([
    {
      collection: "category",
      orderBy: ["categoryName", "asc"],
    },
  ]);
  const category = useSelector((state) => state.firestore.ordered.category);

  const cart = useSelector((state) => state.firestore.ordered.cart);

  const searchTermValue = () => {
    let searchTextTerm = searchTerm.current.value;
    setSearchText(searchTextTerm);
  };

  const logoutUser = async () => {
    await firebase.logout();
    router.push("/login");
  };

  const showSearchBar = () => {
    setIsSearch(true);
  };

  const hideSearchBar = () => {
    setIsSearch(false);
  };

  return (
    <>
      <MenuDrawar
        showMenu={show}
        onHide={handleClose}
        menuClose={handleClose}
        logoutUser={logoutUser}
      />
      <header className={styles.navigationBox}>
        <div className={styles.topHeader}>
          <Container>
            <div className="row">
              <div className="col-md-3 col-2">
                <div className={styles.mobileMenu}>
                  <img
                    onClick={handleShow}
                    className={styles.menuIcon}
                    src={mobileMenuImg}
                    alt={"Menu"}
                  />

                  {screenSize >= 767 && (
                    <img
                      onClick={showSearchBar}
                      className={styles.searchImg}
                      src={searchIcon}
                      alt={"cart"}
                    />
                  )}
                </div>
              </div>

              <div className="col-md-6 col-6">
                <div className={styles.brandBox}>
                  <Link className={styles.brandName} to="/">
                    {winLocation &&
                    winLocation === "https://www.hcmseller.com" ? (
                      <img
                        className={styles.brandLogo}
                        src={brandLogo}
                        alt={"HCM Seller"}
                      />
                    ) : (
                      <div>MPS Group</div>
                    )}
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-4">
                <div className={styles.cartBox}>
                  {screenSize <= 767 && (
                    <img
                      onClick={showSearchBar}
                      className={styles.searchImg}
                      src={searchIcon}
                      alt={"hcm seller"}
                    />
                  )}
                  <Link to="/cart">
                    {cart && cart.length > 0 && <span>{cart.length}</span>}
                    <img
                      className={styles.cartImg}
                      src={cartImg}
                      alt={"cart"}
                    />
                  </Link>
                </div>
              </div>
            </div>

            {isSearch && (
              <Row className={styles.searchContainer}>
                <div className="offset-md-4 col-md-4 col-12">
                  <div className={`${styles.brandBox}`}>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Search"
                        ref={searchTerm}
                        className="noOutline"
                        onBlurCapture={searchTermValue}
                      />
                      <Link to={`/search/${searchText}`}>
                        <Button
                          className="noOutline"
                          variant="primary"
                          id="button-addon2"
                        >
                          Search
                        </Button>
                      </Link>
                      <img
                        onClick={hideSearchBar}
                        className={styles.crossImg}
                        src={crossIcon}
                        alt={"hcm seller"}
                      />
                    </InputGroup>
                  </div>
                </div>
              </Row>
            )}
          </Container>
        </div>

        {screenSize >= 767 && (
          <div className={styles.headerBox}>
            <Container>
              <Navbar
                collapseOnSelect={true}
                expanded={menu}
                expand="md"
                variant="dark"
                className={styles.navBar}
              >
                <Navbar.Toggle
                  className={styles.toggleBtn}
                  onClick={() => setMenu(menu ? false : true)}
                  aria-controls="basic-navbar-nav"
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  {category && (
                    <Nav className={`mr-auto ${styles.navLink}`}>
                      <Link to={"/"}>Home</Link>
                      {category &&
                        category.slice(0, 9).map((cat) => (
                          <Fragment key={cat.id}>
                            <Link to={`/products/${cat.id}`}>
                              {cat.categoryName}
                            </Link>
                          </Fragment>
                        ))}
                    </Nav>
                  )}
                </Navbar.Collapse>
              </Navbar>
            </Container>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;

import React from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./ProductCard.module.scss";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { DeviceUUID } from "device-uuid";
import { useSelector } from "react-redux";
import whatsappImg from '../../../assets/images/whatsapp.svg';
import {WHATSAPP_ENQ_NO} from '../../../common/Constant';


const ProductCard = ({ id, name, image, alt, price, stockStatus }) => {
  const firestore = useFirestore();
  const history = useHistory();
  var uuid = new DeviceUUID().get();

  useFirestoreConnect([
    {
      collection: "cart",
      where: [["deviceId", "==", uuid]],
    },
  ]);

  const cart = useSelector((state) => state.firestore.ordered.cart);
  const auth = useSelector((state) => state.firebase.auth)

  const goToCart = () => {
    history.push("/cart");
  };

  const addToCart = (id) => {
    var uuid = new DeviceUUID().get();
    const cartData = {
      deviceId: uuid,
      productId: id,
      qty: 1,
    };
    firestore.collection("cart").add({
      ...cartData,
      createdAt: firestore.FieldValue.serverTimestamp(),
    });
  };

  return (
    <div className={styles.productCard}>
      {auth.email &&(
      <Link to={`/add-product/${id}`}>
          <button className={styles.editBtn} type="button">Edit</button>
      </Link>
      )}
      
      <Link to={`/product/${id}`}>
        <img src={image} className={styles.productImg} alt={alt} />
        <h2 className={styles.headingText}>{name}</h2>
        <div className={styles.infoBox}>
          <div className={styles.conText}>&#8377; {price}</div>
          <div className={styles.conText}>{stockStatus}</div>
        </div>
      </Link>

        <div className="row">
            <div className="col-md-8 col-9">
            {cart && cart.find((cartItem) => cartItem.productId == id) ? (
                <button onClick={goToCart} className="btn btn-primary btn-sm">
                View Cart
                </button>
            ) : (
                <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => addToCart(id)}
                >
                Add to Cart
                </button>
            )}
            </div>

            <div className="col-md-4 col-3">
            <a href={`https://wa.me/${WHATSAPP_ENQ_NO}?text=*Enquiry Details*%0A${'Product Name:' +name}%0A${'Price:' +price}%0A${'StockStatus: '+stockStatus}`}>
                    <img className={styles.whatsappImg} src={whatsappImg} alt="hcm" />
                </a>
            </div>
            </div>
    </div>
  );
};

export default ProductCard;

import React from "react";
import { Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BRAND_NAME } from "../../../common/Constant";
import styles from "./MenuDrawar.module.scss";

const MenuDrawar = ({ showMenu, onHide, menuClose, logoutUser }) => {
  var screenSize = window.screen.width;
  const winLocation = window.location.origin;
  const category = useSelector((state) => state.firestore.ordered.category);
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <>
      <Offcanvas
        style={{ backgroundColor: "#fff" }}
        show={showMenu}
        onHide={onHide}
        placement={"start"}
        //   backdrop={false}
        //   scroll={false}
      >
        <Offcanvas.Header className={styles.header} closeButton>
          <Offcanvas.Title>
            {winLocation && winLocation === "https://www.hcmseller.com"
              ? BRAND_NAME
              : "MPS Group"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.drawarBox}>
          {auth.email && (
            <>
              <div onClick={menuClose} className={styles.navLink}>
                <Link to="/orders">Orders</Link>
              </div>
              <div onClick={menuClose} className={styles.navLink}>
                <Link to="/add-product">Add Product</Link>
              </div>
              <div onClick={menuClose} className={styles.navLink}>
                <Link to="/add-category">Add Category</Link>
              </div>
            </>
          )}

          {!auth.email && (
            <div onClick={menuClose} className={styles.navLink}>
              <Link to="/login">Login</Link>
            </div>
          )}

          {auth.email && (
            <div className={styles.navLink} onClick={logoutUser}>
              Logout
            </div>
          )}

          <div className={styles.seprater}></div>

          {screenSize <= 767 && (
            <>
              {category &&
                category.map((cat) => (
                  <div
                    key={cat.id}
                    onClick={menuClose}
                    className={styles.navLink}
                  >
                    <Link to={`/products/${cat.id}`}>{cat.categoryName}</Link>
                  </div>
                ))}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MenuDrawar;

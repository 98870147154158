import React, { useState } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CategoryCard from '../../components/organisms/categoryCard/CategoryCard';
import s from './Home.module.scss';
import BannerSlider from '../../components/organisms/slider/Slider';
import ProductCard from '../../components/organisms/productcard/ProductCard';
import logo from '../../assets/images/hcmlogo.png';
import Carousel from "react-multi-carousel";



const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };

const Home = () => {

    const [limits, setLimits] = useState(12)

    useFirestoreConnect([
        {
            collection:'products',
            limit: limits,
            orderBy: ['createdAt', 'desc']
        }
    ])

    const category = useSelector((state) => state.firestore.ordered.category)
    const products = useSelector((state) => state.firestore.ordered.products)
    const auth = useSelector((state) => state.firebase.auth)

    
    return (
        <>
        <BannerSlider/>
        <div className={s.homePage}>

        <div className={s.categorySection}>
            <div className="container">
        {category &&(
                <div className="row">
                    <div className="col-md-6 col-6">
                <h4 className={s.headText}>Browse by Category</h4>
                </div>
                {auth.email && (
                    <div className="col-md-6 col-6">
                    <Link to="/categories">
                    <h4 className={s.catBtn}>View All Category</h4>
                    </Link>
                    </div>
                )}
               </div>
               
                )}
                
                {category &&(
                    <div className="row">
            <Carousel 
            responsive={responsive}
            >
                {/* <div>Item 1</div>
                <div>Item 2</div>
                <div>Item 3</div>
                <div>Item 4</div> */}

                {category.map(cat =>(
                    <div key={cat.id} className={`col-md-2 col-4 ${s.categoryBox}`}>
                        <Link to={`/products/${cat.id}`}>
                            <CategoryCard
                            src={cat.categoryImg || `${logo}`}
                            alt={cat.categoryName}
                            heading={cat.categoryName}
                            />
                        </Link>
                        </div>
                ))}
                </Carousel>
            </div>
                )}
            
            
            </div>
               </div>

<div className="container">
            <div className="row">
                {products &&(
                    <div className="col-md-12">
                <h4 className={s.headText}>Latest Products</h4>
                </div>
                )}
                
            {products && products.map(product =>(
                <div className="col-md-3 col-6" key={product.id}>
                <ProductCard 
                id={product.id}
                name={product.productName}
                image={product.productImg}
                alt={product.productName}
                price={product.price}
                stockStatus={product.stockStatus}
                />
                </div>
            ))}
            
            </div>

            {products &&(
            <div className="row">
            <div className="offset-md-5 col-md-2 col-12 d-grid">
            <button className={`btn btn-warning ${s.loadMoreBtn} noOutline`} onClick={()=>setLimits(limits +12)}>Load More</button>
            </div>
            </div>
            )}
            </div>
        </div>
        </>
    )
}

export default Home;

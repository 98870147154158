import React, {useState} from 'react';
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from 'react-redux';
import s from './Orders.module.scss';
import OrderItems from '../../components/organisms/OrderItems/OrderItems'
import {Modal, Button} from 'react-bootstrap';

const Orders = () => {

    const [limits, setLimits] = useState(5)
    const firestore = useFirestore();
    const [show, setShow] = useState(false);
    const [orderedItem, setOrderedItem] = useState();

  const handleClose = () => setShow(false);

  const handleShow = async (id) => {
      try {
        const docRef = firestore.collection("orders").doc(id);
        const result = await docRef.get();
        if (result.exists) {
          setOrderedItem(result.data().items);
          setShow(true);
        } else {
          alert("somthing went wrong");
        }
      } catch (e) {
        console.log("error", e.message);
      }
  }

    useFirestoreConnect([
        {
            collection:'orders',
            orderBy: ['createdAt', 'desc'],
            limit: limits,
        }
    ])
    
    const orders = useSelector((state) => state.firestore.ordered.orders)

    return (
        <div className={`container ${s.orderBox}`}>
            <div className={s.headingText}>Enquiry</div>
            {orders && orders.map(order =>(
                <div className={`row ${s.orderItem}`} key={order.id}>
                <div className="offset-md-2 col-12 col-md-8">
                    <p>Name: {order.name}</p>
                    <p>Phone: {order.phone}</p>
                    <p>Address: {order.address}</p>
                    <button 
                        className={`btn btn-warning ${s.viewBtn}`}
                        onClick={() => handleShow(order.id)}
                        >
                        View Item
                    </button>
                </div>
                </div>
            ))}

            {orderedItem && (
                <Modal 
                show={show}
                onHide={handleClose}
                centered
                backdrop="static"
                className={s.modelBox}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Ordered Item</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {orderedItem.map(item=>(
                        <OrderItems key={item.productId} id={item.productId} qty={item.qty}/>
                    ))}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
                </Modal>
            )}

            {orders &&(
            <div className="row">
            <div className="offset-md-5 col-md-2 col-12 d-grid">
            <button className={`btn btn-warning ${s.loadMoreBtn} noOutline`} onClick={()=>setLimits(limits +5)}>Load More</button>
            </div>
            </div>
            )}
        </div>
    )
}

export default Orders;

import React, {useRef, useState, useEffect } from 'react';
import { useFirestore, useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useParams, useHistory } from "react-router-dom";
import {Form, Button, Col, Row } from 'react-bootstrap';
import uploadImg from '../../assets/images/uploadimage.png'
import { v4 as uuidv4 } from 'uuid';
import styles from './AddCategory.module.scss';

const AddCategory = () => {

  let history = useHistory()
  const firestore = useFirestore();
  const firebase = useFirebase();
  const { id } = useParams();
  const uuid = uuidv4();
  var categoryName = useRef(null);

  const [catImage, setCatImage] = useState('');
  const [catImagePath, setCatImagePath] = useState('');


  const onSubmitHandle= (e) =>{
    e.preventDefault();
    if(id){
        updateData()
    }else{
      const formData ={
        categoryName: categoryName.current.value.toLowerCase(),
        categoryImg: ''
      }
    const storageRef = firebase.storage().ref()
    const imageRef = storageRef.child('category/'+uuid);
    const uploadTask = imageRef.put(catImage)
    uploadTask.on("state_changed", console.log, console.error, () => {
    imageRef.getDownloadURL()
    .then((url) => {
      formData.categoryImg = url;
      firestore.collection("category")
      .add({
          ...formData,
          createdAt: firestore.FieldValue.serverTimestamp()
        })
    });
    
    })
    history.push('/');
    }
  }

  const updateData = async ()=>{
    var formData ={
      categoryName: categoryName.current.value.toLowerCase(),
      createdAt: firestore.FieldValue.serverTimestamp()
    }
    if(catImage){
    const storageRef = firebase.storage().ref()
    const imageRef = storageRef.child('category/'+uuid);
    const uploadTask = imageRef.put(catImage)
    uploadTask.on("state_changed", console.log, console.error, () => {
      imageRef.getDownloadURL()
      .then((url) => {
        const updateRef = firestore.collection("category").doc(id);
        updateRef.update({
          ...formData,
          categoryImg: url,
          createdAt: firestore.FieldValue.serverTimestamp()
        })
          history.push('/');
      });
    })
    }else{
      const updateRef = firestore.collection("category").doc(id);
      updateRef.update({
        ...formData
      })
      history.push('/categories');
    }
      
  }

  const getCategoryById = async () =>{
    if(id){
    try {
        const docRef = firestore.collection("category").doc(id);
        const result = await docRef.get();
        if(result.exists){
          const formData =result.data()
          if(id){
            categoryName.current.value = formData.categoryName;
            setCatImagePath(formData.categoryImg)
          }
        }else{
            alert('somthing went wrong');
        }
    } catch (e) {
        console.log('error', e.message);
    }
  }
} 

useEffect(() =>{
    getCategoryById();
  },[]);

  const onImageChange = (e) => {
    const reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(file);
          setCatImage(file);
          setCatImagePath(reader.result)
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setCatImage(null);
    }
  };

    return (
        <div className="container">
      <form onSubmit={onSubmitHandle}>
        <div className={`offset-md-3 col-md-6 ${styles.addProductPage}`}>
          <div className={styles.imgUploadBox}>
            <input
            type="file"
            accept="image/x-png,image/jpeg"
            onChange={(e) => {onImageChange(e); }}
          />
          <img className={styles.imgUpload} src={uploadImg} alt="img" />
         </div>
         {catImagePath &&(
          <img className={styles.preViewImg} src={catImagePath} alt="img"/>
         )}

        <Form.Group>
          <Form.Label>Category Name *</Form.Label>
          <Form.Control type="text"
           placeholder="type your category name"
           ref={categoryName}
           required={true}
           />
        </Form.Group>

        <div className={`d-grid ${styles.submitBtn}`}>
        <Button type="submit" variant="primary">
        {id ? "Update Category" : "Add Category"}
        </Button>
        </div>

        </div>
      </form>
    </div>
    )
}

export default AddCategory;

import React, {useState} from 'react'
import ProductCard from "../../components/organisms/productcard/ProductCard";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


const SearchResult = () => {
  const { searchText } = useParams();
  const [limits, setLimits] = useState(8);
  console.log(searchText);

    useFirestoreConnect([
        {
          collection: "products",
          where: ["productName", ">=", searchText ],
          where: ["productName", "<=", searchText +"\uf8ff"],
          orderBy:['productName', 'desc'],
          limit: limits,
        },
      ]);

      const products = useSelector((state) => state.firestore.ordered.products);

    return (
        <div className="container">
            <div className="row">
                {products && products.map(product =>(
                    <div className="col-md-3" key={product.id}>
                <ProductCard
                id={product.id}
                name={product.productName}
                description={product.productDescription}
                image={product.productImg}
                alt={product.productName}
                price={product.price}
                stockStatus={product.stockStatus}
              />
              </div>
            ))}
            </div>

            {products &&(
                    <div className="row">
                <div className="offset-md-5 col-md-2 col-12 d-grid">
                        <button className={`btn btn-warning`} onClick={()=>setLimits(limits +8)}>Load More</button>
                        </div>
                </div>
                )}
            
        </div>
    )
}

export default SearchResult;

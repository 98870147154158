import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useParams, useHistory } from "react-router-dom";
import ProductCard from "../../components/organisms/productcard/ProductCard";
import styles from './Product.module.scss';
import { DeviceUUID } from "device-uuid";

const Product = () => {
  const firestore = useFirestore();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  var uuid = new DeviceUUID().get();
  const history = useHistory();

  const getProductDetails = async () => {
    try {
      const docRef = firestore.collection("products").doc(id);
      const result = await docRef.get();
      if (result.exists) {
        setProduct(result.data());
        setCategoryId(result.data().categoryId)
        // console.log(result.data().categoryId)
      } else {
        alert("somthing went wrong");
      }
    } catch (e) {
      console.log("error", e.message);
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  useFirestoreConnect([
      {
          collection:'products',
          limit:4,
          where: [['categoryId', '==', categoryId]],
      }
    ])

    useFirestoreConnect([
      {
        collection: "cart",
        where: [["deviceId", "==", uuid]],
      },
    ]);
  
    const cart = useSelector((state) => state.firestore.ordered.cart);
  
    const goToCart = () => {
      history.push("/cart");
    };
  
    const addToCart = (id) => {
      var uuid = new DeviceUUID().get();
      const cartData = {
        deviceId: uuid,
        productId: id,
        qty: 1,
      };
      firestore.collection("cart").add({
        ...cartData,
        createdAt: firestore.FieldValue.serverTimestamp(),
      });
    };


     const products = useSelector((state) => state.firestore.ordered.products)    

  if (!product) {
    return <h5>loading...</h5>;
  }



  return (
    <div className={`container ${styles.prodDetailsPage}`}>
      <div className="row">
        <div className="col-md-6 col-sm-12 col-xs-12">
          <img className={styles.prodImg} alt={product.productName} src={product.productImg} />
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12">
        <h2 className={styles.headText}>{product.productName}</h2>
        <p className={styles.stockStatus}>{product.stockStatus}</p>
          <p className={styles.context}>{product.productDescription}</p>
          <p className={styles.spanText}><b>Price:</b> {product.price}</p>
          <p className={styles.spanText}><b>Moq:</b> {product.moq}</p>
          
          <div>
          {cart && cart.find((cartItem) => cartItem.productId == id) ? (
                <button onClick={goToCart} className="btn btn-primary">
                View Cart
                </button>
            ) : (
                <button
                className="btn btn-outline-primary"
                onClick={() => addToCart(id)}
                >
                Add to Cart
                </button>
            )}
            </div>

        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h2 className={styles.titleText}>More Products</h2>
        </div>
      {products && products.map((prod) => (
          <div onClick={getProductDetails} className={`col-md-3 col-sm-6 col-6 ${styles.productsBox}`} key={prod.id}>
            <ProductCard 
            id={prod.id}
            name={prod.productName}
            description={prod.productDescription}
            image={prod.productImg}
            alt={prod.productName}
            price={prod.price}
            stockStatus={prod.stockStatus}
            />                        
          </div>
      ))}
      </div>

    </div>
  );
};

export default Product;

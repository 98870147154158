import React from 'react';
import {Carousel} from 'react-bootstrap';
import slider1 from '../../../assets/slider/sb-slider6.jpeg';
import slider2 from '../../../assets/slider/sb-slider5.jpeg';
import slider3 from '../../../assets/slider/sb-slider3.jpeg';
import style from './Slider.module.scss';


const BannerSlider = () => {
    return (
        <div>
    <Carousel indicators={false} nextLabel={''} prevLabel={''}>
  <Carousel.Item >
    <img
      className={style.sliderImg}
      src={slider1}
      alt="First slide"
    />
  </Carousel.Item>

  <Carousel.Item>
    <img
      className={style.sliderImg}
      src={slider2}
      alt="Second slide"
    />
  </Carousel.Item>

  <Carousel.Item>
    <img
      className={style.sliderImg}
      src={slider3}
      alt="Second slide"
    />
  </Carousel.Item>

</Carousel>
        </div>
    )
}

export default BannerSlider;
